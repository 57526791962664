import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { DEFAULT_LOCATIONS } from "./data/locations";
import { FaStar } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const SurfCoaches = () => {
  const { findCity, findState } = useParams(); // Retrieves from the URL
  const navigate = useNavigate();
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [findCityTwo, setFindCityTwo] = useState(findCity || "");
  const [findStateTwo, setFindStateTwo] = useState(findState || "");
  const [numberOfCoaches, setNumberOfCoaches] = useState("");
  const [filtering, setFiltering] = useState("");
  const [loading, setLoading] = useState(false);
  const [debugInfo, setDebugInfo] = useState("");
  const [coachRatings, setCoachRatings] = useState({});

  const [allCitiesTwo] = useState(DEFAULT_LOCATIONS.cities);
  const [allStatesTwo] = useState(DEFAULT_LOCATIONS.states);

  const firstCoachRef = useRef(null);

  const filterAndSortCoaches = (findCityTwo, findStateTwo, coaches) => {
    if (!findCityTwo || !findStateTwo || !coaches) {
      setDebugInfo((prev) => prev + "\nMissing required data for filtering");
      setFilteredCoaches([]);
      setNumberOfCoaches("No Coaches Found. Coming Soon!");
      return;
    }

    const filtered = coaches.filter((coach) => {
      const coachCities = Array.isArray(coach.cities)
        ? coach.cities.map((city) => city?.toLowerCase?.().trim() || "")
        : typeof coach.cities === "string"
        ? [coach.cities.toLowerCase().trim()]
        : [];

      const searchCity = findCityTwo.toLowerCase().trim();
      const searchState = findStateTwo.toLowerCase().trim();
      const coachState = (coach.state || "").toLowerCase().trim();

      return coachState === searchState && coachCities.includes(searchCity);
    });

    setDebugInfo(
      (prev) => prev + `\nFound ${filtered.length} matching coaches`
    );
    setFilteredCoaches(filtered);
    setNumberOfCoaches(
      filtered.length === 0
        ? "No Coaches Found. Coming Soon!"
        : filtered.length === 1
        ? "1 Coach Found"
        : `${filtered.length} Coaches Found`
    );
  };

  // Fetch coaches when component mounts or when a search is submitted
  useEffect(() => {
    const fetchCoaches = async () => {
      setLoading(true);
      setDebugInfo("Starting to fetch coaches...");
      try {
        const coachesResponse = await axios.get(
          "https://findsurfinglessons.onrender.com/api/coaches"
        );

        setDebugInfo(
          (prev) => prev + `\nReceived ${coachesResponse.data.length} coaches`
        );

        if (findCityTwo || findStateTwo) {
          setDebugInfo(
            (prev) => prev + `\nFiltering for ${findCityTwo}, ${findStateTwo}`
          );
          filterAndSortCoaches(findCityTwo, findStateTwo, coachesResponse.data);
        }
      } catch (error) {
        setDebugInfo((prev) => prev + `\nError: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchCoaches();
  }, [findCityTwo, findStateTwo]); // Only trigger on location change or search submission

  useEffect(() => {
    if (findCityTwo && findStateTwo) {
      navigate(`/start-your-journey/${findCityTwo}/${findStateTwo}`);
    }
  }, [findCityTwo, findStateTwo, navigate]);

  // Add this new useEffect after the other useEffect hooks
  useEffect(() => {
    if (filteredCoaches.length > 0 && firstCoachRef.current) {
      firstCoachRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [filteredCoaches]);

  // Add this new useEffect to fetch ratings
  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await axios.get(
          "https://findsurfinglessons.onrender.com/api/coach-ratings"
        );
        setCoachRatings(response.data);
      } catch (error) {
        console.error("Error fetching coach ratings:", error);
      }
    };
    fetchRatings();
  }, []); // Empty dependency array means this runs once on component mount

  const handleSubmit = (e) => {
    e.preventDefault();
    setFindCityTwo(findCityTwo);
    setFindStateTwo(findStateTwo);
    navigate(`/start-your-journey/${findCityTwo}/${findStateTwo}`);
  };

  const handleFiltering = (event) => {
    const selected_option = event.target.value;
    setFiltering(event.target.value);
    if (selected_option === "Private Lesson Rate") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => a.privatelessonrate - b.privatelessonrate
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Group Lesson Rate") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => a.grouplessonrate - b.grouplessonrate
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Experience") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => b.experience - a.experience
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Alphabetical") {
      const sortedItems = [...filteredCoaches].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setFilteredCoaches(sortedItems);
    }
  };

  const renderStars = (coachId) => {
    const rating = coachRatings[coachId]?.averageRating || 0;
    const reviewCount = coachRatings[coachId]?.reviewCount || 0;
    const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5

    return (
      <div className="coach-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <FaStar
            key={star}
            className={star <= roundedRating ? "filled" : "empty"}
            style={{
              color: star <= roundedRating ? "#FFD700" : "#e4e5e9",
              marginRight: "2px",
            }}
          />
        ))}
        <span className="rating-text">
          {rating > 0 ? `(${rating})` : "(No ratings yet)"}
        </span>
        {reviewCount > 0 && (
          <span className="review-count">
            • {reviewCount} {reviewCount === 1 ? "review" : "reviews"}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="surf-coaches-wrapper">
      <Helmet>
        <title>
          Find Local Surf Coaches | Professional Surf Instructors Near You
        </title>
        <meta
          name="description"
          content="Browse and connect with certified surf coaches in your area. Compare rates, experience levels, and reviews to find your perfect instructor. Book private or group lessons today."
        />
        <link
          rel="canonical"
          href="https://getsurfinglessons.com/surf-coaches"
        />
        <meta
          name="keywords"
          content="surf coaches, surf instructors, local surf lessons, private surf coaching, group surf lessons, certified surf instructors"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            name: "Find Local Surf Coaches",
            description:
              "Browse and connect with certified surf coaches in your area. Compare rates, experience levels, and reviews.",
            provider: {
              "@type": "Organization",
              name: "Get Surfing Lessons",
              url: "https://getsurfinglessons.com",
            },
            about: {
              "@type": "Thing",
              name: "Surf Instruction",
              description:
                "Professional surf coaching and instruction services",
            },
            offers: {
              "@type": "AggregateOffer",
              offerCount: filteredCoaches.length,
              lowPrice:
                filteredCoaches.length > 0
                  ? Math.min(
                      ...filteredCoaches.map((coach) => coach.privatelessonrate)
                    )
                  : "50",
              highPrice:
                filteredCoaches.length > 0
                  ? Math.max(
                      ...filteredCoaches.map((coach) => coach.privatelessonrate)
                    )
                  : "150",
              priceCurrency: "USD",
              offers: filteredCoaches.map((coach) => ({
                "@type": "Offer",
                name: `Surf Lessons with Coach ${coach.first_name}`,
                price: coach.privatelessonrate,
                priceCurrency: "USD",
                itemOffered: {
                  "@type": "Service",
                  name: "Private Surf Lesson",
                  description: `Private surf instruction with Coach ${coach.first_name}`,
                },
                seller: {
                  "@type": "Person",
                  name: `Coach ${coach.first_name}`,
                  image: coach.profile_picture,
                  jobTitle: "Surf Coach",
                  knowsAbout: ["Surfing", "Surf Instruction", "Ocean Safety"],
                  hasCredential: {
                    "@type": "EducationalOccupationalCredential",
                    name: "Professional Surf Instructor",
                    educationalLevel: "Professional",
                  },
                },
              })),
            },
            mainEntity: {
              "@type": "ItemList",
              numberOfItems: filteredCoaches.length,
              itemListElement: filteredCoaches.map((coach, index) => ({
                "@type": "ListItem",
                position: index + 1,
                item: {
                  "@type": "Person",
                  name: `Coach ${coach.first_name}`,
                  image: coach.profile_picture,
                  jobTitle: "Surf Coach",
                  experienceYears: coach.experience,
                  makesOffer: [
                    {
                      "@type": "Offer",
                      name: "Private Surf Lessons",
                      price: coach.privatelessonrate,
                      priceCurrency: "USD",
                    },
                    {
                      "@type": "Offer",
                      name: "Group Surf Lessons",
                      price: coach.grouplessonrate,
                      priceCurrency: "USD",
                    },
                  ],
                  review: coachRatings[coach.id]
                    ? {
                        "@type": "AggregateRating",
                        ratingValue: coachRatings[coach.id].averageRating,
                        reviewCount: coachRatings[coach.id].reviewCount,
                        bestRating: "5",
                        worstRating: "1",
                      }
                    : undefined,
                },
              })),
            },
            potentialAction: {
              "@type": "SearchAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate:
                  "https://getsurfinglessons.com/start-your-journey/{city}/{state}",
                actionPlatform: [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/MobileWebPlatform",
                ],
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                valueRequired: true,
                valueName: "location",
              },
            },
            isPartOf: {
              "@type": "WebSite",
              name: "Get Surfing Lessons",
              url: "https://getsurfinglessons.com",
            },
          })}
        </script>
      </Helmet>
      <div className="search-header">
        <div className="search-container surfcoaches-search-container">
          <h1 className="search-title">Find Your Perfect Surf Coach</h1>
          <h2 className="search-location">
            {findCityTwo && findStateTwo ? (
              <>
                Exploring coaches in {findCityTwo}, {findStateTwo.toUpperCase()}
              </>
            ) : (
              "Select your location to begin"
            )}
          </h2>

          <form onSubmit={handleSubmit} className="location-form">
            <div className="location-selects">
              <div className="surf-coach-select-group">
                <i className="fas fa-map-marker-alt"></i>
                <select
                  id="city-dropdown"
                  value={findCityTwo}
                  onChange={(e) => setFindCityTwo(e.target.value)}
                  className="location-select"
                >
                  <option value="">
                    {allCitiesTwo.length === 0 ? "Loading..." : "Select A City"}
                  </option>
                  {allCitiesTwo.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>

              <div className="surf-coach-select-group">
                <i className="fas fa-globe-americas"></i>
                <select
                  id="state-dropdown"
                  value={findStateTwo}
                  onChange={(e) => setFindStateTwo(e.target.value)}
                  className="location-select"
                >
                  <option value="">
                    {allStatesTwo.length === 0
                      ? "Loading..."
                      : "Select A State"}
                  </option>
                  {allStatesTwo.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="results-section">
        <div className="results-header">
          <div className="results-info">
            <h3 className="results-count">{numberOfCoaches}</h3>
            <select
              value={filtering}
              onChange={handleFiltering}
              className="sort-select"
            >
              <option value="" disabled>
                Sort Coaches By
              </option>
              <option value="Private Lesson Rate">Private Lesson Rate</option>
              <option value="Group Lesson Rate">Group Lesson Rate</option>
              <option value="Experience">Years of Experience</option>
              <option value="Alphabetical">Alphabetical Order</option>
            </select>
          </div>
        </div>

        <div className="coaches-grid">
          {loading ? (
            <div className="loading-state">
              <div className="loading-spinner"></div>
              <p>Discovering surf coaches in your area...</p>
            </div>
          ) : filteredCoaches.length > 0 ? (
            filteredCoaches.map((coach, index) => (
              <div
                className="coach-profile-card"
                key={coach.id}
                ref={index === 0 ? firstCoachRef : null}
              >
                <div className="coach-image-container">
                  <img
                    src={coach.profile_picture}
                    alt={`Coach ${coach.first_name}`}
                    className="coach-image"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "../../public/images/surf-2.jpg";
                    }}
                  />
                  <div className="experience-badge">
                    {coach.experience}
                    {coach.experience === 1
                      ? " Year Experience"
                      : " Years Experience"}
                  </div>
                </div>

                <div className="coach-info">
                  <h2 className="coach-name">Coach {coach.first_name}</h2>
                  <div className="coach-location">
                    <i className="fas fa-map-marker-alt"></i>
                    {findCityTwo}, {findStateTwo.toUpperCase()}
                  </div>

                  {renderStars(coach.id)}

                  <div className="coach-rates">
                    <div className="rate-item">
                      <span className="rate-label">Private Lessons</span>
                      <span className="rate-value">
                        ${coach.privatelessonrate}/hr
                      </span>
                    </div>
                    <div className="rate-item">
                      <span className="rate-label">Group Lessons</span>
                      <span className="rate-value">
                        ${coach.grouplessonrate}/hr
                      </span>
                    </div>
                  </div>

                  <Link
                    to={`/start-your-journey/${findCityTwo}/${findStateTwo}/${coach.id}`}
                    state={{ coachData: coach }}
                    className="view-profile-link"
                  >
                    View Full Profile
                  </Link>
                </div>
              </div>
            ))
          ) : (
            findCityTwo &&
            findStateTwo && (
              <div className="no-results">
                <h3>No coaches found in this area yet</h3>
                <p>Try searching in nearby cities or check back soon!</p>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SurfCoaches;
