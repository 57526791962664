import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function About() {
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const [contentImages, setContentImages] = useState({
    honoluaba: false,
    surfingCoach: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload hero image
    const heroImage = new Image();
    heroImage.src = `${process.env.PUBLIC_URL}/images/new-1.jpg`;
    heroImage.onload = () => {
      setHeroImageLoaded(true);
    };

    // Preload content images
    const imagesToLoad = [
      { key: "honoluaba", path: "/images/honoluaba.jpg" },
      { key: "surfingCoach", path: "/images/surfing-coach.jpg" },
    ];

    imagesToLoad.forEach(({ key, path }) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${path}`;
      img.onload = () => {
        setContentImages((prev) => ({
          ...prev,
          [key]: true,
        }));
      };
    });
  }, []);

  return (
    <div className="about-page">
      <Helmet>
        <title>
          About Get Surfing Lessons - Your Journey to Surfing Excellence
        </title>
        <meta
          name="description"
          content="Learn about Get Surfing Lessons, connecting passionate surf coaches with aspiring surfers. Discover our mission, values, and commitment to the surfing community."
        />
        <link rel="canonical" href="https://getsurfinglessons.com/about" />
        <meta
          name="keywords"
          content="surf school, surf lessons, surf coaching, surfing community, learn to surf, surf instruction"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Get Surfing Lessons",
            url: "https://getsurfinglessons.com",
            logo: "https://getsurfinglessons.com/logo.png",
            foundingDate: "2023",
            description:
              "Get Surfing Lessons connects passionate surf coaches with aspiring surfers, providing personalized surf instruction and building a vibrant surfing community.",
            sameAs: [
              "https://facebook.com/getsurfinglessons",
              "https://instagram.com/getsurfinglessons",
              "https://twitter.com/getsurflessons",
            ],
            address: {
              "@type": "PostalAddress",
              addressCountry: "US",
            },
            areaServed: {
              "@type": "Country",
              name: "United States",
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              reviewCount: "100",
              bestRating: "5",
              worstRating: "1",
            },
            award: [
              "100+ Successful Lessons Completed",
              "10+ Certified Coaches",
              "20+ Locations Served",
            ],
            slogan: "Connecting Passionate Surfers Since 2023",
            knowsAbout: [
              "Surf Instruction",
              "Ocean Safety",
              "Surfing Techniques",
              "Water Sports",
            ],
            hasOfferCatalog: {
              "@type": "OfferCatalog",
              name: "Surf Instruction Services",
              itemListElement: [
                {
                  "@type": "Offer",
                  itemOffered: {
                    "@type": "Service",
                    name: "Private Surf Lessons",
                    description: "One-on-one personalized surf instruction",
                  },
                },
                {
                  "@type": "Offer",
                  itemOffered: {
                    "@type": "Service",
                    name: "Group Surf Lessons",
                    description: "Small group surf instruction",
                  },
                },
              ],
            },
            brand: {
              "@type": "Brand",
              name: "Get Surfing Lessons",
              slogan: "Your Journey to Surfing Excellence",
              logo: "https://getsurfinglessons.com/logo.png",
            },
            memberOf: [
              {
                "@type": "Organization",
                name: "Professional Surf Instructors Association",
              },
            ],
            keywords: [
              "surf lessons",
              "surf coaching",
              "learn to surf",
              "surf instruction",
              "surfing community",
              "ocean safety",
              "surf school",
            ],
            specialty: [
              "Beginner Surf Lessons",
              "Advanced Surf Coaching",
              "Ocean Safety Training",
              "Surf Technique Development",
            ],
            ethicsPolicy: {
              "@type": "CreativeWork",
              name: "Core Values",
              text: "Passion for surfing, Community building, Excellence in instruction, Safety first",
            },
            potentialAction: [
              {
                "@type": "FindAction",
                target: "https://getsurfinglessons.com/start-your-journey",
              },
              {
                "@type": "JoinAction",
                target: "https://getsurfinglessons.com/apply-to-coach",
              },
            ],
          })}
        </script>
      </Helmet>

      <div
        className={`about-hero ${heroImageLoaded ? "image-loaded" : ""}`}
        style={{
          backgroundColor: "#0077b6", // Placeholder color while loading
          backgroundImage: heroImageLoaded
            ? `linear-gradient(rgba(0, 119, 182, 0.6), rgba(0, 77, 182, 0.7)), url("${process.env.PUBLIC_URL}/images/new-1.jpg")`
            : "none",
        }}
      >
        <h1>About Get Surfing Lessons</h1>
        <p>Connecting Passionate Surfers Since 2023</p>
      </div>

      <section className="about-section">
        <div className="content-wrapper">
          <div className="text-content">
            <h2>Our Story</h2>
            <p>
              Born from my lifelong passion for surfing and connecting people,
              Get Surfing Lessons emerged from a simple observation: finding the
              right surf coach shouldn't be complicated and expensive. As an
              avid surfer and tech enthusiast, I noticed a gap between amazing
              surf coaches and eager learners. Traditional surf schools, while
              great, often lack the personal touch and flexibility that modern
              surfers need. Our platform works differently. We directly connect
              you with certified local coaches, letting you choose based on
              their style, experience, and reviews. No middleman, no rigid
              schedules, just straightforward connections. Coaches set their own
              rates and availability, while students can browse profiles, read
              reviews, and book lessons that fit their schedule. What sets us
              apart? Unlike traditional surf schools or generic booking
              platforms, we focus solely on surf coaching and building lasting
              connections. Our coaches aren't just instructors – they're mentors
              who share their local knowledge, favorite spots, and authentic
              surf culture. Plus, our flexible booking system and direct
              communication mean better prices for students and fair
              compensation for coaches.
            </p>
          </div>
          <div className="image-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/images/honoluaba.jpg`}
              alt="Surfer at sunset"
              className={`about-image ${
                contentImages.honoluaba ? "loaded" : ""
              }`}
              loading="lazy"
              fetchpriority="high"
            />
          </div>
        </div>
      </section>

      <section className="about-section values-section">
        <h2>Our Core Values</h2>
        <div className="values-grid">
          <div className="value-card">
            <i className="fas fa-heart"></i>
            <h3>Passion</h3>
            <p>
              We're driven by our deep passion for surf culture, wave riding,
              and protecting our ocean playgrounds. Our commitment to
              sustainable surfing practices and marine conservation ensures
              future generations can enjoy the waves.
            </p>
          </div>
          <div className="value-card">
            <i className="fas fa-users"></i>
            <h3>Community</h3>
            <p>
              Join our thriving surf community where beginners and experienced
              surfers connect, share wave knowledge, and embrace surf culture.
              Experience the unique camaraderie of dawn patrols and post-surf
              sessions.
            </p>
          </div>
          <div className="value-card">
            <i className="fas fa-star"></i>
            <h3>Excellence</h3>
            <p>
              Experience world-class surf coaching from certified instructors
              who excel in teaching surf techniques, wave reading, and ocean
              awareness. Our proven teaching methods ensure rapid progression
              from foam boards to green waves.
            </p>
          </div>
          <div className="value-card">
            <i className="fas fa-shield-alt"></i>
            <h3>Safety</h3>
            <p>
              Your safety in the surf is our top priority. Learn essential ocean
              safety skills, surf spot assessment, and emergency protocols from
              experienced watermen who understand local break conditions and
              surf dynamics.
            </p>
          </div>
        </div>
      </section>

      <section className="about-section impact-section">
        <div className="content-wrapper reverse">
          <div className="text-content">
            <h2>Our Impact</h2>
            <div className="impact-stats">
              <div className="stat">
                <h3>100+</h3>
                <p>Successful Lessons</p>
              </div>
              <div className="stat">
                <h3>10+</h3>
                <p>Certified Coaches</p>
              </div>
              <div className="stat">
                <h3>20+</h3>
                <p>Locations</p>
              </div>
            </div>
          </div>
          <div className="image-wrapper">
            <img
              src={`${process.env.PUBLIC_URL}/images/surfing-coach.jpg`}
              alt="Group surf lesson"
              className={`about-image ${
                contentImages.surfingCoach ? "loaded" : ""
              }`}
              loading="lazy"
              fetchpriority="low"
            />
          </div>
        </div>
      </section>

      <section className="about-section commitment-section">
        <h2>Our Commitment to You</h2>
        <div className="commitment-grid">
          <div className="commitment-card">
            <h3>Quality Instruction</h3>
            <p>
              Every coach is vetted and certified to ensure high-quality lessons
            </p>
          </div>
          <div className="commitment-card">
            <h3>Flexible Scheduling</h3>
            <p>Book lessons that fit your schedule, any day of the week</p>
          </div>
          <div className="commitment-card">
            <h3>Perfect Match</h3>
            <p>
              We'll help you find the right coach for your skill level and goals
            </p>
          </div>
        </div>
      </section>
      <section className="about-section cta-section">
        <h2>Ready to Start Your Surfing Journey?</h2>
        <p>
          Join our community of surfers and experience the thrill of riding the
          waves
        </p>
        <div className="cta-buttons">
          <Link to="/start-your-journey">
            <button className="cta-button primary">Find Your Coach</button>
          </Link>
          <Link to="/apply-to-coach">
            <button className="cta-button secondary">Become a Coach</button>
          </Link>
        </div>
      </section>
    </div>
  );
}
