import { useState, useEffect, lazy, Suspense } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function HomePage() {
  const [homeBackgroundImage, setHomeBackgroundImage] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload critical background images
    const backgroundImg = new Image();
    backgroundImg.src = "/images/background-2.jpg";
    backgroundImg.onload = () => {
      setHomeBackgroundImage(`url(/images/background-2.jpg)`);
      setImagesLoaded(true);
    };

    // Intersection Observer for lazy loading
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(document.querySelector(".home-content"));

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Best Surf Lessons Near Me | Private & Group Classes from $50/Hour |
          California, Hawaii, Florida
        </title>
        <meta
          name="description"
          content="Find top-rated surf lessons near you with certified local instructors. Private lessons from $50/hour in California (LA, Orange County), Hawaii (Waikiki), and Florida. Perfect for beginners! Book same-day lessons with professional surf coaches. ⭐️ 4.9/5 Rating"
        />
        <meta
          name="keywords"
          content="surf lessons near me, surfing lessons, learn to surf, private surf lessons, group surf lessons, beginner surf lessons, California surf lessons, Hawaii surf lessons, Florida surf lessons, professional surf coach"
        />
        <link rel="canonical" href="https://getsurfinglessons.com/" />

        {/* Location-specific meta links */}
        <link
          rel="alternate"
          href="https://getsurfinglessons.com/california"
          hreflang="en-us"
        />
        <link
          rel="alternate"
          href="https://getsurfinglessons.com/hawaii"
          hreflang="en-us"
        />
        <link
          rel="alternate"
          href="https://getsurfinglessons.com/florida"
          hreflang="en-us"
        />

        {/* Breadcrumb Schema */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://getsurfinglessons.com"
              }]
            }
          `}
        </script>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <meta name="theme-color" content="#0077b6" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <meta name="format-detection" content="telephone=no" />

        {/* Add Local Business Schema */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "SportsActivityLocation",
              "name": "Get Surfing Lessons",
              "image": "https://getsurfinglessons.com/images/surf-lesson-main.jpg",
              "@id": "https://getsurfinglessons.com",
              "url": "https://getsurfinglessons.com",
              "telephone": "+1-XXX-XXX-XXXX",
              "priceRange": "$50-200",
              "address": [{
                "@type": "PostalAddress",
                "addressLocality": "Huntington Beach",
                "addressRegion": "CA",
                "addressCountry": "US"
              },
              {
                "@type": "PostalAddress",
                "addressLocality": "Waikiki",
                "addressRegion": "HI",
                "addressCountry": "US"
              }],
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": "33.6595",
                "longitude": "-117.9988"
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "opens": "06:00",
                "closes": "19:00"
              },
              "sameAs": [
                "https://www.facebook.com/getsurfinglessons",
                "https://www.instagram.com/getsurfinglessons"
              ],
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
              },
              "offers": {
                "@type": "Offer",
                "price": "50.00",
                "priceCurrency": "USD",
                "availability": "https://schema.org/InStock",
                "validFrom": "2024-01-01",
                "priceValidUntil": "2024-12-31"
              }
            }
          `}
        </script>
      </Helmet>

      <div
        className={`home ${imagesLoaded ? "images-loaded" : ""}`}
        style={{ backgroundImage: homeBackgroundImage }}
      >
        {!imagesLoaded && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
          </div>
        )}

        <div className="home-header">
          <div className="home-header-content">
            <div className="home-header-container">
              <span className="hero-eyebrow">
                Experience the Thrill of Surfing
              </span>
              <h1>
                Discover Your Perfect
                <br />
                Surf Journey
              </h1>
              <p>
                Connect with expert surf coaches who will guide you from your
                first paddle to catching waves with confidence. Your surfing
                adventure begins here.
              </p>
              <div className="hero-cta-group">
                <Link to="/start-your-journey">
                  <button className="primary-cta">
                    Find Your Coach <i className="fas fa-arrow-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="home-content">
          <div className="surf-coach-container">
            <h2>Why Choose Us?</h2>
            <div className="features-grid">
              <div className="feature-card">
                <i className="fas fa-star"></i>
                <h3>Find Your Style</h3>
                <p>
                  Select a beginner-friendly coach that matches your vibe.
                  Private lessons from $50!
                </p>
              </div>
              <div className="feature-card">
                <i className="fas fa-clock"></i>
                <h3>Flexible Scheduling</h3>
                <p>
                  Choose lesson times that work for you, from weekend mornings
                  to weekday afternoons.
                </p>
              </div>
              <div className="feature-card">
                <i className="fas fa-map-marker-alt"></i>
                <h3>Local Expertise</h3>
                <p>
                  Connect with local coaches to discover the best surf spots in
                  your area.
                </p>
              </div>
            </div>

            <div className="experience-section">
              <div className="experience-content">
                <i className="fas fa-users"></i>
                <h3>Personalized Experience</h3>
                <p>
                  Skip expensive surf schools. Get personalized one-on-one
                  coaching from local experts and make surfing your lifestyle.
                  Learn at your own pace, on your schedule, for less.
                </p>
              </div>
            </div>

            <div className="cta-container">
              <img
                src="/images/surfing-coach-1.jpg"
                alt="Professional surf coach teaching a student"
                loading="lazy"
                width="400"
                height="300"
                className="cta-image"
                onLoad={(e) => {
                  e.target.classList.add("image-loaded");
                }}
              />
              <h3>Ready to Catch Your First Wave?</h3>
              <p>
                Join our community of surfers and find your perfect coach today!
              </p>
              <Link to="/start-your-journey">
                <button className="find-coach-button">
                  Find Your Surfing Coach
                </button>
              </Link>
            </div>

            <div className="testimonials">
              <h3>What Our Surfers Say</h3>
              <div className="testimonial-grid">
                <div className="testimonial-card">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left"></i>
                    <p>
                      "Found an amazing coach through Get Surfing Lessons.
                      Standing up on my first wave was unforgettable!"
                    </p>
                    <div className="testimonial-author">
                      <div>
                        <h4>-Sarah M.</h4>
                        <p>California</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testimonial-card">
                  <div className="testimonial-content">
                    <i className="fas fa-quote-left"></i>
                    <p>
                      "The personalized attention helped me progress faster than
                      any group lesson could."
                    </p>
                    <div className="testimonial-author">
                      <div>
                        <h4>-Mike R.</h4>
                        <p>Hawaii</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info-sections">
          <div className="info-section surfers">
            <div className="info-content">
              <h3>For Aspiring Surfers</h3>
              <div className="info-features">
                <div className="info-feature">
                  <i className="fas fa-graduation-cap"></i>
                  <h4>Personalized Lessons</h4>
                  <p>
                    Expert surf instruction with personalized one-on-one
                    coaching, from beginner surfing techniques to advanced wave
                    riding skills. Our certified instructors adapt each lesson
                    to match your experience level.
                  </p>
                </div>
                <div className="info-feature">
                  <i className="fas fa-calendar-alt"></i>
                  <h4>Flexible Schedule</h4>
                  <p>
                    Choose from morning, afternoon, or weekend surf lessons at
                    your convenience. We offer year-round scheduling options to
                    catch the best waves and tides for optimal learning
                    conditions.
                  </p>
                </div>
                <div className="info-feature">
                  <i className="fas fa-map-marked-alt"></i>
                  <h4>Perfect Coach Match</h4>
                  <p>
                    Browse detailed instructor profiles, teaching styles, and
                    authentic reviews to find your ideal surf coach. Match with
                    an instructor whose personality and teaching approach aligns
                    with your learning style and surfing goals. Pay your surfing
                    coach directly after the lesson.
                  </p>
                </div>
              </div>
              <Link to="/start-your-journey">
                <button className="cta-button">Start Your Journey</button>
              </Link>
            </div>
          </div>

          <div className="info-section coaches">
            <div className="info-content">
              <h3>For Surf Coaches</h3>
              <div className="info-features">
                <div className="info-feature">
                  <i className="fas fa-percentage"></i>
                  <h4>Keep 100% of Earnings</h4>
                  <p>
                    Maximize your surf instructor income with zero commission
                    fees. Keep every dollar you earn directly from teaching
                    surfing lessons, with transparent pricing and direct
                    payments from students.
                  </p>
                </div>
                <div className="info-feature">
                  <i className="fas fa-user-circle"></i>
                  <h4>Free Profile</h4>
                  <p>
                    Create your free surf instructor profile to showcase your
                    certifications, teaching experience, and favorite surf
                    spots. Share photos and student testimonials to attract more
                    surfing students.
                  </p>
                </div>
                <div className="info-feature">
                  <i className="fas fa-clock"></i>
                  <h4>Flexible Schedule</h4>
                  <p>
                    Set your own surfing lesson hours and availability. Whether
                    you teach part-time or full-time, easily manage your
                    calendar to balance teaching surf lessons with your perfect
                    wave schedule.
                  </p>
                </div>
              </div>
              <Link to="/apply-to-coach">
                <button className="cta-button">Become a Coach</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="featured-locations">
          <h2>Popular Surfing Locations</h2>
          <div className="location-grid">
            <div className="location-card">
              <img src="/images/california.jpg" alt="California Surfing" />
              <h3>California</h3>
              <p>
                Huntington Beach, Newport Beach, Laguna Beach, San Clemente,
                Dana Point, Santa Monica, Los Angeles, Santa Barbara, San Diego,
                San Francisco
              </p>
            </div>
            <div className="location-card">
              <img src="/images/hawaii1.jpg" alt="Hawaii Surfing" />
              <h3>Hawaii</h3>
              <p>
                Waikiki Beach, North Shore, Maui, Kauai, Big Island, Kihei,
                Kauai, Oahu, Molokai, Lanai, Hana, Kahului, Wailuku, Kailua
              </p>
            </div>
            <div className="location-card">
              <img src="/images/florida.jpg" alt="Florida Surfing" />
              <h3>Florida</h3>
              <p>
                Jacksonville, Cocoa, Daytona, Melbourne, St. Augustine, Key
                West, Miami, Fort Lauderdale, Palm Beach, West Palm Beach
              </p>
            </div>
            {/* Add more location cards */}
          </div>
        </div>
        <div className="quick-links">
          <h2>Explore More</h2>
          <div className="links-grid">
            <Link to="/blogs">
              <div className="link-card">
                <i className="fas fa-book-open"></i>
                <h3>Surfing Blog</h3>
                <p>Tips, tricks, and surfing stories</p>
              </div>
            </Link>
            <Link to="/questions">
              <div className="link-card">
                <i className="fas fa-question-circle"></i>
                <h3>FAQ</h3>
                <p>Common questions answered</p>
              </div>
            </Link>
            <Link to="/safety">
              <div className="link-card">
                <i className="fas fa-shield-alt"></i>
                <h3>Safety Guidelines</h3>
                <p>Important safety information</p>
              </div>
            </Link>
          </div>
        </div>
        <div className="legal-notice">
          <p>
            Before booking lessons, please review our{" "}
            <Link to="/safety" className="legal-link">
              Safety Guidelines
            </Link>{" "}
            <Link to="/terms" className="legal-link">
              Terms of Service
            </Link>
            and{" "}
            <Link to="/privacy" className="legal-link">
              Privacy Policy
            </Link>
          </p>
        </div>
        <div className="stats-section">
          <div className="stat-card animate-on-scroll">
            <i className="fas fa-user-friends stat-icon"></i>
            <div className="stat-content">
              <h3 className="counter">20+</h3>
              <p>Active Coaches</p>
            </div>
          </div>
          <div className="stat-card animate-on-scroll">
            <i className="fas fa-smile-beam stat-icon"></i>
            <div className="stat-content">
              <h3 className="counter">100+</h3>
              <p>Satisfied Students</p>
            </div>
          </div>
          <div className="stat-card animate-on-scroll">
            <i className="fas fa-map-marker-alt stat-icon"></i>
            <div className="stat-content">
              <h3 className="counter">20+</h3>
              <p>Locations</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
