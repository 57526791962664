import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DEFAULT_LOCATIONS } from "./data/locations";
import { Helmet } from "react-helmet-async";

const StartYourJourney = () => {
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);
  const [findCity, setFindCity] = useState("");
  const [findState, setFindState] = useState("");
  const [allCities, setAllCities] = useState(DEFAULT_LOCATIONS.cities);
  const [allStates, setAllStates] = useState(DEFAULT_LOCATIONS.states);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload hero image
    const heroImage = new Image();
    heroImage.src = `${process.env.PUBLIC_URL}/images/surfing-photo.jpg`;
    heroImage.onload = () => {
      setHeroImageLoaded(true);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!findCity || !findState) {
      alert("Please select both a city and state");
      return;
    }

    navigate(`/start-your-journey/${findCity}/${findState}`, {
      state: {
        city: findCity.trim(),
        state: findState.trim(),
        scrollToFirstCoach: true,
      },
    });
  };
  return (
    <>
      <Helmet>
        <title>Start Your Surfing Journey | Book Surf Lessons Today</title>
        <meta
          name="description"
          content="Begin your surfing adventure with professional local coaches. Find certified instructors in your area and book your first lesson today starting at $50. Group and private lessons available."
        />
        <link
          rel="canonical"
          href="https://getsurfinglessons.com/start-your-journey"
        />
        <meta
          name="keywords"
          content="book surf lessons, learn to surf, surfing lessons near me, private surf lessons, group surf lessons, beginner surf lessons, surf instructor, surf coach"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            name: "Professional Surf Lessons",
            description:
              "Expert surfing instruction for all skill levels. Our certified local coaches provide personalized lessons focusing on surf technique, ocean safety, and confidence building. Available for individuals, groups, and families.",
            provider: {
              "@type": "Organization",
              name: "Get Surfing Lessons",
              url: "https://getsurfinglessons.com",
              logo: "https://getsurfinglessons.com/logo.png",
              sameAs: [
                "https://facebook.com/getsurfinglessons",
                "https://instagram.com/getsurfinglessons",
                "https://twitter.com/getsurflessons",
              ],
            },
            areaServed: {
              "@type": "Country",
              name: "United States",
              hasMap: "https://getsurfinglessons.com/locations",
            },
            serviceType: "Surfing Instruction",
            offers: [
              {
                "@type": "Offer",
                name: "Private Surf Lesson",
                description:
                  "One-on-one personalized surf instruction with a certified coach",
                price: "50.00",
                priceCurrency: "USD",
                availability: "https://schema.org/InStock",
                priceValidUntil: "2024-12-31",
                url: "https://getsurfinglessons.com/private-lessons",
              },
              {
                "@type": "Offer",
                name: "Group Surf Lesson",
                description:
                  "Small group surf instruction with a certified coach",
                price: "35.00",
                priceCurrency: "USD",
                availability: "https://schema.org/InStock",
                priceValidUntil: "2024-12-31",
                url: "https://getsurfinglessons.com/group-lessons",
              },
            ],
            termsOfService: "https://getsurfinglessons.com/terms",
            audience: {
              "@type": "Audience",
              audienceType: "Beginner to Advanced Surfers",
              geographicArea: {
                "@type": "GeoCircle",
                geoMidpoint: {
                  "@type": "GeoCoordinates",
                  latitude: "34.0522",
                  longitude: "-118.2437",
                },
                geoRadius: "5000",
              },
            },
            hasOfferCatalog: {
              "@type": "OfferCatalog",
              name: "Surf Lesson Programs",
              itemListElement: [
                {
                  "@type": "OfferCatalog",
                  name: "Beginner Surf Lessons",
                  description:
                    "Learn ocean safety, proper stance, wave reading basics, paddling techniques, and pop-up practice",
                },
                {
                  "@type": "OfferCatalog",
                  name: "Intermediate Surf Lessons",
                  description:
                    "Advanced wave selection, turn techniques, speed generation, surf etiquette, and equipment selection",
                },
                {
                  "@type": "OfferCatalog",
                  name: "Advanced Surf Lessons",
                  description:
                    "Aerial maneuvers, competition strategies, advanced wave riding, performance surfing, and big wave techniques",
                },
              ],
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.8",
              reviewCount: "235",
              bestRating: "5",
              worstRating: "1",
            },
            review: [
              {
                "@type": "Review",
                reviewRating: {
                  "@type": "Rating",
                  ratingValue: "5",
                  bestRating: "5",
                },
                author: {
                  "@type": "Person",
                  name: "Sarah Johnson",
                },
                datePublished: "2024-01-15",
                reviewBody:
                  "Amazing experience! The instructor was patient and knowledgeable. I was standing up on waves by the end of my first lesson!",
              },
            ],
            isAccessibleForFree: false,
            serviceOutput: {
              "@type": "Thing",
              name: "Surfing Skills Development",
              description:
                "Students will learn proper surfing techniques, ocean safety, and gain confidence in the water",
            },
            potentialAction: {
              "@type": "ReserveAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate: "https://getsurfinglessons.com/book",
                actionPlatform: [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/MobileWebPlatform",
                ],
              },
            },
          })}
        </script>
      </Helmet>
      <div className="start-your-journey">
        <div
          className={`hero-section ${heroImageLoaded ? "image-loaded" : ""}`}
          style={{
            backgroundColor: "#1976d2", // Placeholder color while image loads
            backgroundImage: heroImageLoaded
              ? `linear-gradient(rgba(33, 113, 156, 0.2), rgba(57, 103, 168, 0.2)), url("${process.env.PUBLIC_URL}/images/surfing-photo.jpg")`
              : "none",
          }}
        >
          <div className="hero-content">
            <div className="hero-text-container">
              <h1 className="hero-title">Begin Your Surfing Adventure</h1>
              <p className="hero-subtitle">
                Connect with professional surf coaches and ride the waves with
                confidence
              </p>
            </div>
            <div className="search-container-wrapper">
              <div className="search-container-start-your-journey">
                <form onSubmit={handleSubmit} className="search-form">
                  <div className="search-steps">
                    <div className="search-step">
                      <div className="step-indicator">1</div>
                      <span>Choose your city</span>
                    </div>
                    <div className="search-step">
                      <div className="step-indicator">2</div>
                      <span>Select your state</span>
                    </div>
                  </div>

                  <div className="select-wrapper">
                    <div className="select-group">
                      <i className="fas fa-map-marker-alt"></i>
                      <select
                        id="city-dropdown"
                        value={findCity}
                        onChange={(e) => setFindCity(e.target.value)}
                        className="search-select"
                      >
                        <option value="">Select Your City</option>
                        {allCities.map((city, index) => (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="select-group">
                      <i className="fas fa-globe-americas"></i>
                      <select
                        id="state-dropdown"
                        value={findState}
                        onChange={(e) => setFindState(e.target.value)}
                        className="search-select"
                      >
                        <option value="">Select Your State</option>
                        {allStates.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <button type="submit" className="search-button">
                    <i className="fas fa-search"></i>
                    Find Your Coach
                  </button>

                  <div className="search-features">
                    <div className="feature">
                      <i className="fas fa-certificate"></i>
                      <span>Certified Instructors</span>
                    </div>
                    <div className="feature">
                      <i className="fas fa-user-shield"></i>
                      <span>Verified Profiles</span>
                    </div>
                    <div className="feature">
                      <i className="fas fa-hand-holding-usd"></i>
                      <span>Best Local Rates</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="journey-content">
          <section className="learning-process">
            <h2 className="section-title">Your Journey to Mastery</h2>
            <div className="process-steps">
              <div className="step-card">
                <div className="step-number">1</div>
                <h3>Choose Your Location</h3>
                <p>
                  Find certified coaches in your preferred surfing destination
                </p>
              </div>
              <div className="step-card">
                <div className="step-number">2</div>
                <h3>Browse Coaches</h3>
                <p>Compare profiles and find your perfect instructor match</p>
              </div>
              <div className="step-card">
                <div className="step-number">3</div>
                <h3>Book Your Lesson</h3>
                <p>Schedule your session and start your surfing journey</p>
              </div>
            </div>
          </section>

          <section className="features-section">
            <h2 className="section-title">Why Choose Us?</h2>
            <div className="features-grid">
              <div className="feature-card">
                <i className="fas fa-certificate"></i>
                <h3>Certified Coaches</h3>
                <p>
                  Learn essential surfing techniques from professional
                  instructors certified in ocean safety and surf coaching. Our
                  vetted surf coaches bring years of experience teaching
                  beginners to advanced surfers at top breaks nationwide.
                </p>
              </div>
              <div className="feature-card">
                <i className="fas fa-users"></i>
                <h3>Personalized Learning</h3>
                <p>
                  Experience surf lessons tailored to your progression, from
                  paddle techniques to wave selection and advanced maneuvers.
                  Your surf coach adapts each session to match your learning
                  style and surfing goals, ensuring rapid improvement.
                </p>
              </div>
              <div className="feature-card">
                <i className="fas fa-map-marked-alt"></i>
                <h3>Prime Locations</h3>
                <p>
                  Discover the best surf spots for your skill level, from gentle
                  beach breaks perfect for beginners to challenging reef breaks
                  for experienced surfers. Learn local wave conditions, tides,
                  and surf etiquette at premier surfing locations.
                </p>
              </div>
              <div className="feature-card">
                <i className="fas fa-shield-alt"></i>
                <h3>Safety First</h3>
                <p>
                  Master ocean safety fundamentals, including rip current
                  awareness, surf forecasting, and emergency protocols. Get
                  expert guidance on proper surfboard selection, wetsuit
                  fitting, and essential surf safety equipment for confident
                  wave riding.
                </p>
              </div>
            </div>
          </section>
          <section className="skill-levels">
            <h2 className="section-title">Programs for Every Level</h2>
            <div className="level-cards">
              <div className="level-card beginner">
                <div className="level-header">
                  <h3>Beginner</h3>
                  <span className="level-tag">Start Here</span>
                </div>
                <ul className="level-features">
                  <li>
                    <i className="fas fa-check"></i>Ocean safety fundamentals
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Proper stance and
                    positioning
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Wave reading basics
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Paddling techniques
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Pop-up practice
                  </li>
                </ul>
              </div>

              <div className="level-card intermediate">
                <div className="level-header">
                  <h3>Intermediate</h3>
                  <span className="level-tag">Level Up</span>
                </div>
                <ul className="level-features">
                  <li>
                    <i className="fas fa-check"></i>Advanced wave selection
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Turn techniques
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Speed generation
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Surf etiquette
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Equipment selection
                  </li>
                </ul>
              </div>

              <div className="level-card advanced">
                <div className="level-header">
                  <h3>Advanced</h3>
                  <span className="level-tag">Master</span>
                </div>
                <ul className="level-features">
                  <li>
                    <i className="fas fa-check"></i>Aerial maneuvers
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Competition strategies
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Advanced wave riding
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Performance surfing
                  </li>
                  <li>
                    <i className="fas fa-check"></i>Big wave techniques
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default StartYourJourney;
