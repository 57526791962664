import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function Blogs() {
  const location = useLocation();
  const [loadedImages, setLoadedImages] = useState({
    surfLessons: false,
    maui: false,
    huntington: false,
    losAngeles: false,
    sanDiego: false,
    jacksonville: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload all blog images
    const imagesToLoad = [
      { key: "surfLessons", path: "/images/surf_lessons.jpg" },
      { key: "maui", path: "/images/hawaii.jpg" },
      { key: "huntington", path: "/images/huntington.jpg" },
      { key: "losAngeles", path: "/images/losangeles.jpg" },
      { key: "sanDiego", path: "/images/sandiego.jpg" },
      { key: "jacksonville", path: "/images/jacksonville.jpg" },
    ];

    imagesToLoad.forEach(({ key, path }) => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}${path}`;
      img.onload = () => {
        setLoadedImages((prev) => ({
          ...prev,
          [key]: true,
        }));
      };
    });
  }, [location]);

  const BlogCard = ({ to, imageKey, imagePath, alt, title, description }) => (
    <Link to={to} className="blog-link">
      <div className="blog-card">
        <div className={`blog-image ${imageKey}-image`}>
          <div
            className={`image-placeholder ${
              loadedImages[imageKey] ? "hidden" : ""
            }`}
          />
          <img
            src={`${process.env.PUBLIC_URL}${imagePath}`}
            alt={alt}
            className={loadedImages[imageKey] ? "loaded" : ""}
            loading="lazy"
          />
        </div>
        <div className="blog-content">
          <h2>{title}</h2>
          <p>{description}</p>
          <span className="read-more">Read More →</span>
        </div>
      </div>
    </Link>
  );

  return (
    <div className="blogs-container">
      <Helmet>
        <title>Surf Blog Adventures - Get Surfing Lessons</title>
        <meta
          name="description"
          content="Explore surfing guides, tips, and locations across the US. Learn about surfing spots in Maui, Los Angeles, San Diego, and more."
        />
        <link rel="canonical" href="https://getsurfinglessons.com/blogs" />
        <meta
          name="keywords"
          content="surf blog, surfing guides, surf spots, surf locations, surf tips, surfing lessons"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            name: "Surf Blog Adventures",
            description:
              "Comprehensive guides and articles about surfing locations, lessons, and tips across the United States",
            url: "https://getsurfinglessons.com/blogs",
            publisher: {
              "@type": "Organization",
              name: "Get Surfing Lessons",
              logo: {
                "@type": "ImageObject",
                url: "https://getsurfinglessons.com/logo.png",
              },
            },
            blogPost: [
              {
                "@type": "BlogPosting",
                headline: "Surfing Lessons Guide",
                description:
                  "Learn the basics of surfing and how to catch a wave",
                image: "https://getsurfinglessons.com/images/surf_lessons.jpg",
                url: "https://getsurfinglessons.com/blogs/surf-lessons",
                datePublished: "2024-01-01",
                dateModified: new Date().toISOString(),
              },
              {
                "@type": "BlogPosting",
                headline: "Maui Surfing Guide",
                description:
                  "Discover the best surfing spots and lessons in Maui, Hawaii",
                image: "https://getsurfinglessons.com/images/hawaii.jpg",
                url: "https://getsurfinglessons.com/blogs/maui",
                datePublished: "2024-01-01",
                dateModified: new Date().toISOString(),
              },
              {
                "@type": "BlogPosting",
                headline: "Huntington Beach Guide",
                description:
                  "Unraveling the Coastal Charm: Exploring the Natural Beauty of Huntington State Beach",
                image: "https://getsurfinglessons.com/images/huntington.jpg",
                url: "https://getsurfinglessons.com/blogs/huntington",
                datePublished: "2024-01-01",
                dateModified: new Date().toISOString(),
              },
              {
                "@type": "BlogPosting",
                headline: "Los Angeles Surf Guide",
                description:
                  "Riding the Waves: Unforgettable Surf Lessons in Los Angeles",
                image: "https://getsurfinglessons.com/images/losangeles.jpg",
                url: "https://getsurfinglessons.com/blogs/losangeles",
                datePublished: "2024-01-01",
                dateModified: new Date().toISOString(),
              },
              {
                "@type": "BlogPosting",
                headline: "San Diego Surf Guide",
                description:
                  "Master the Waves of San Diego: Unleash your inner surfer with top-notch surf lessons",
                image: "https://getsurfinglessons.com/images/sandiego.jpg",
                url: "https://getsurfinglessons.com/blogs/sandiego",
                datePublished: "2024-01-01",
                dateModified: new Date().toISOString(),
              },
              {
                "@type": "BlogPosting",
                headline: "Jacksonville Surf Guide",
                description:
                  "Catch the Waves and Enjoy Unforgettable Surfing Lessons in Jacksonville",
                image: "https://getsurfinglessons.com/images/jacksonville.jpg",
                url: "https://getsurfinglessons.com/blogs/jacksonville",
                datePublished: "2024-01-01",
                dateModified: new Date().toISOString(),
              },
            ],
            about: {
              "@type": "Thing",
              name: "Surfing",
              description:
                "Information about surfing locations, techniques, and lessons",
            },
            keywords: [
              "surfing guides",
              "surf spots",
              "surf lessons",
              "beach locations",
              "surfing tips",
              "learn to surf",
            ],
            inLanguage: "en-US",
            isPartOf: {
              "@type": "WebSite",
              name: "Get Surfing Lessons",
              url: "https://getsurfinglessons.com",
            },
            potentialAction: {
              "@type": "ReadAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate: "https://getsurfinglessons.com/blogs/{article}",
                actionPlatform: [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/MobileWebPlatform",
                ],
              },
            },
          })}
        </script>
      </Helmet>

      <h1 className="blogs-title">Surf Blog Adventures</h1>
      <div className="blogs-grid">
        <BlogCard
          to="/blogs/surf-lessons"
          imageKey="surfLessons"
          imagePath="/images/surf_lessons.jpg"
          alt="Surfing Lessons"
          title="Surfing Lessons Guide"
          description="Learn the basics of surfing and how to catch a wave"
        />

        <BlogCard
          to="/blogs/maui"
          imageKey="maui"
          imagePath="/images/hawaii.jpg"
          alt="Maui Surfing"
          title="Maui Surfing Guide"
          description="Discover the best surfing spots and lessons in Maui, Hawaii"
        />

        <BlogCard
          to="/blogs/huntington"
          imageKey="huntington"
          imagePath="/images/huntington.jpg"
          alt="Huntington Beach"
          title="Huntington Beach"
          description="Unraveling the Coastal Charm: Exploring the Natural Beauty of Huntington State Beach"
        />

        <BlogCard
          to="/blogs/losangeles"
          imageKey="losAngeles"
          imagePath="/images/losangeles.jpg"
          alt="Los Angeles"
          title="Los Angeles Surf Guide"
          description="Riding the Waves: Unforgettable Surf Lessons in Los Angeles"
        />

        <BlogCard
          to="/blogs/sandiego"
          imageKey="sanDiego"
          imagePath="/images/sandiego.jpg"
          alt="San Diego"
          title="San Diego Surf Guide"
          description="Master the Waves of San Diego: Unleash your inner surfer with top-notch surf lessons"
        />

        <BlogCard
          to="/blogs/jacksonville"
          imageKey="jacksonville"
          imagePath="/images/jacksonville.jpg"
          alt="Jacksonville"
          title="Jacksonville Surf Guide"
          description="Catch the Waves and Enjoy Unforgettable Surfing Lessons in Jacksonville"
        />
      </div>
    </div>
  );
}
