import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css"; // Add this line at the top of your JavaScript/JSX file
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const Modal = ({
  isOpen,
  onClose,
  coachfirstName,
  coachlastName,
  coachCity,
  coachEmail,
  coachPhoneNumber,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [level, setLevel] = useState("");
  const [comments, setComments] = useState("");
  const [submit, setSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for tracking submission process
  const [showContactSuccess, setShowContactSuccess] = useState(false);

  if (!isOpen) return null; //don't render anything if isopen is false, otherwise render the form

  const onSubmit = async (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("age", age);
    formData.append("gender", gender);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("level", level);
    formData.append("comments", comments);
    formData.append("coachfirstName", coachfirstName);
    formData.append("coachlastName", coachlastName);
    formData.append("coachCity", coachCity);
    formData.append("coachEmail", coachEmail);
    formData.append("coachPhoneNumber", coachPhoneNumber);

    const response = await fetch(
      "https://findsurfinglessons.onrender.com/api/students",
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      setSubmit(true);
      setShowContactSuccess(true);
      setTimeout(() => {
        setShowContactSuccess(false);
        onClose();
      }, 3000);
    } else {
      console.error("Failed to submit form");
      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {submit ? (
          <div className="contact-success-overlay">
            <div className="contact-success-modal">
              <div className="contact-success-content">
                <div className="contact-success-icon">
                  <FaCheckCircle />
                </div>
                <h2 className="contact-success-title">Message Sent!</h2>
                <p className="contact-success-message">
                  Your message has been sent to Coach {coachfirstName}. They
                  will get back to you soon!
                </p>
                <button onClick={onClose} className="contact-success-btn">
                  Continue
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <button className="close-button" onClick={onClose}>
              <i className="fa fa-times" aria-hidden="true"></i>{" "}
              {/* Font Awesome X icon */}
            </button>
            <h2 className="contact-modal-title">Contact Coach {coachfirstName}</h2>
            <p className="contact-info">
              Please fill out this form and I will reach out to you as soon as
              possible!
            </p>
            <form onSubmit={onSubmit}>
              <input
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                type="text"
                placeholder="First Name"
                className="contact-input-field"
                required
              />
              <input
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                type="text"
                placeholder="Last Name"
                className="contact-input-field"
                required
              />
              <input
                onChange={(e) => setAge(e.target.value)}
                value={age}
                type="text"
                placeholder="Age"
                className="contact-input-field"
                required
              />
              <input
                onChange={(e) => setGender(e.target.value)}
                value={gender}
                type="text"
                placeholder="Gender"
                className="contact-input-field"
                required
              />
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                placeholder="Email"
                className="contact-input-field"
                required
              />
              <input
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                type="text"
                placeholder="Phone Number"
                className="contact-input-field"
                required
              />
              <select
                onChange={(e) => setLevel(e.target.value)}
                value={level}
                className="contact-input-field select-level"
                required
              >
                <option value="" disabled>
                  Select Level
                </option>
                <option value="beginner">Beginner</option>
                <option value="intermediate">Intermediate</option>
                <option value="advances">Advanced</option>
              </select>
              <textarea
                placeholder="Comments/Questions"
                className="contact-input-field comments"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                required
              />
              <div className="agreement-section">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="agreement-checkbox"
                    required
                  />
                  <span className="agreement-text">
                    I agree to the <Link to="/terms">Terms of Service</Link>,{" "}
                    <Link to="/privacy">Privacy Policy</Link> and{" "}
                    <Link to="/safety">Safety Guidelines</Link>
                  </span>
                </label>
              </div>
              <div className="buttons">
                <button type="submit">Submit</button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </>
        )}
      </div>
      {/* Submitting Modal (Pop-up) */}
      {isSubmitting && (
        <div className="submitting-modal-overlay">
          <div className="submitting-modal-content">
            <h3>Submitting your form...</h3>
            <div className="spinner">
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      )}
      {showContactSuccess && (
        <div className="contact-success-overlay">
          <div className="contact-success-modal">
            <div className="contact-success-content">
              <div className="contact-success-icon">
                <FaCheckCircle />
              </div>
              <h2 className="contact-success-title">Message Sent!</h2>
              <p className="contact-success-message">
                Your message has been sent to Coach {coachfirstName}. They will
                get back to you soon!
              </p>
              <button
                onClick={() => setShowContactSuccess(false)}
                className="contact-success-btn"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;