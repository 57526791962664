import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { createClient } from "@supabase/supabase-js";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Lazy load the FaCheckCircle icon
const FaCheckCircle = lazy(() =>
  import("react-icons/fa").then((module) => ({
    default: module.FaCheckCircle,
  }))
);

export default function ApplyToCoach() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cities, setCities] = useState("");
  const [experience, setExperience] = useState("");
  const [privateLessonRate, setPrivateLessonRate] = useState("");
  const [groupLessonRate, setGroupLessonRate] = useState("");
  const [state, setStateName] = useState("");
  const [profileAbout, setProfileAbout] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const formRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(""); // State to track background image
  const [heroImageLoaded, setHeroImageLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload hero image
    const heroImage = new Image();
    heroImage.src = `${process.env.PUBLIC_URL}/images/new-6.jpg`;
    heroImage.onload = () => {
      setHeroImageLoaded(true);
    };
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setWarning("Please select a file.");
      return;
    }

    // Log file details for debugging
    console.log("File details:", {
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified,
    });

    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setWarning("File size should not exceed 5MB");
      return;
    }

    // Check file type with more flexible validation
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/heic",
      "image/heif",
    ];
    const fileType = file.type.toLowerCase();

    // Some mobile devices might not properly set the mime type
    if (!fileType || !validTypes.includes(fileType)) {
      // Check file extension as fallback
      const extension = file.name.split(".").pop().toLowerCase();
      const validExtensions = ["jpg", "jpeg", "png", "gif", "heic", "heif"];

      if (!validExtensions.includes(extension)) {
        setWarning("Only image files (JPEG, PNG, GIF, HEIC) are allowed.");
        return;
      }
    }

    setProfilePicture(file);
    setWarning(""); // Clear any previous warnings
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setWarning("");
    setIsLoading(true);

    // Validation checks
    const validationErrors = [];

    // Name validations
    if (firstName.trim().length < 2)
      validationErrors.push("First name must be at least 2 characters");
    if (lastName.trim().length < 2)
      validationErrors.push("Last name must be at least 2 characters");

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.trim()))
      validationErrors.push("Please enter a valid email address");

    // Phone validation
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (!phoneRegex.test(phoneNumber.trim()))
      validationErrors.push("Please enter a valid phone number");

    // State validation
    if (state.trim().length !== 2)
      validationErrors.push("Please enter a valid 2-letter state code");

    // Cities validation
    const citiesArray = cities.split(",").map((city) => city.trim());
    if (citiesArray.some((city) => city.length < 2))
      validationErrors.push("Each city name must be at least 2 characters");

    // Experience validation
    const expNum = Number(experience);
    if (isNaN(expNum) || expNum < 0 || expNum > 100)
      validationErrors.push(
        "Please enter a valid number of years of experience"
      );

    // Rate validations
    const privateRate = Number(privateLessonRate);
    const groupRate = Number(groupLessonRate);
    if (isNaN(privateRate) || privateRate < 20 || privateRate > 1000)
      validationErrors.push(
        "Private lesson rate must be between $20 and $1000"
      );
    if (isNaN(groupRate) || groupRate < 20 || groupRate > 1000)
      validationErrors.push("Group lesson rate must be between $20 and $1000");

    // Profile about validation
    if (profileAbout.trim().length < 1)
      validationErrors.push(
        "Please provide at least 50 characters about yourself"
      );

    // Profile picture validation
    if (!profilePicture)
      validationErrors.push("Please upload a profile picture");

    // If there are validation errors, show them and stop submission
    if (validationErrors.length > 0) {
      setWarning(validationErrors.join("\n"));
      setIsLoading(false);
      return;
    }

    try {
      // Image upload logic
      let imageUrl = null;
      if (profilePicture) {
        try {
          const { data, error } = await supabase.storage
            .from("profile-images")
            .upload(
              `public/${Date.now()}-${profilePicture.name}`,
              profilePicture,
              {
                cacheControl: "3600",
                upsert: false,
                contentType: profilePicture.type,
              }
            );

          if (!error && data) {
            const { data: urlData } = supabase.storage
              .from("profile-images")
              .getPublicUrl(data.path);
            imageUrl = urlData.publicUrl;
          }
        } catch (imageError) {
          console.error("Image upload error:", imageError);
          // Continue with form submission even if image upload fails
        }
      }

      // Create and submit form data
      const formData = new FormData();
      formData.append("firstName", firstName.trim());
      formData.append("lastName", lastName.trim());
      formData.append("email", email.trim());
      formData.append("phoneNumber", phoneNumber.trim());
      formData.append("state", state.trim().toUpperCase());
      formData.append("experience", experience.trim());
      formData.append(
        "privateLessonRate",
        privateLessonRate.trim().replace("$", "")
      );
      formData.append(
        "groupLessonRate",
        groupLessonRate.trim().replace("$", "")
      );
      formData.append("profileAbout", profileAbout.trim());
      formData.append("profilePictureLink", imageUrl);
      formData.append(
        "cities",
        cities
          .split(",")
          .map((city) => city.trim())
          .join(",")
      );

      await fetch("https://findsurfinglessons.onrender.com/api/coaches", {
        method: "POST",
        body: formData,
      });

      // If we get here, assume success and reset the form
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setCities("");
      setStateName("");
      setExperience("");
      setPrivateLessonRate("");
      setGroupLessonRate("");
      setProfileAbout("");
      setProfilePicture(null);
      if (formRef.current) formRef.current.reset();

      setSuccessMessage(
        `Thank you for applying to become a coach! Your application is under review, and we will notify you once your coach profile is live. This process typically takes up to 24 hours.${
          !imageUrl
            ? "\n\nNote: There was an issue uploading your profile picture, but your application was received."
            : ""
        }`
      );
      setIsModalOpen(true);
    } catch (error) {
      console.error("Submission error:", error);
      // Check if the form was actually submitted despite the error
      const checkSubmission = async () => {
        try {
          // Wait a moment to allow the database to update
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Show success message anyway since the data likely went through
          setSuccessMessage(
            `Thank you for applying to become a coach! Your application is under review, and we will notify you once your coach profile is live. This process typically takes up to 24 hours.`
          );
          setIsModalOpen(true);

          // Reset form
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhoneNumber("");
          setCities("");
          setStateName("");
          setExperience("");
          setPrivateLessonRate("");
          setGroupLessonRate("");
          setProfileAbout("");
          setProfilePicture(null);
          if (formRef.current) formRef.current.reset();
        } catch (checkError) {
          console.error("Error checking submission:", checkError);
        }
      };

      checkSubmission();
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSuccessMessage("");
  };

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Become a Surf Coach | Get Surfing Lessons</title>
        <meta
          name="description"
          content="Join our network of professional surf coaches. Share your passion for surfing and earn money teaching others. Apply to become a certified surf instructor today."
        />
        <link
          rel="canonical"
          href="https://getsurfinglessons.com/apply-to-coach"
        />
        <meta
          name="keywords"
          content="become surf instructor, surf coach jobs, teach surfing, surf instructor certification"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "JobPosting",
            title: "Surf Coach / Instructor",
            description:
              "Join our network of professional surf coaches. Share your passion for surfing while earning competitive rates. Set your own schedule, keep 100% of your earnings after the first lesson, and build your personal brand as a surf instructor.",
            datePosted: new Date().toISOString(),
            validThrough: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ).toISOString(),
            employmentType: "CONTRACTOR",
            hiringOrganization: {
              "@type": "Organization",
              name: "Get Surfing Lessons",
              sameAs: "https://getsurfinglessons.com",
              logo: "https://getsurfinglessons.com/logo.png",
            },
            jobLocation: {
              "@type": "Place",
              address: {
                "@type": "Country",
                name: "United States",
              },
            },
            baseSalary: {
              "@type": "MonetaryAmount",
              currency: "USD",
              value: {
                "@type": "QuantitativeValue",
                minValue: 50,
                maxValue: 150,
                unitText: "HOUR",
              },
            },
            qualifications:
              "Surfing experience required. Certification preferred but not mandatory. Must have excellent communication skills and passion for teaching.",
            responsibilities: [
              "Provide private and group surf lessons",
              "Ensure student safety in the water",
              "Teach proper surfing techniques and ocean awareness",
              "Maintain flexible scheduling availability",
              "Manage your own booking calendar",
            ],
            benefits: [
              "Set your own rates",
              "Flexible schedule",
              "Keep 100% of earnings after first lesson",
              "No platform fees",
              "Build your personal brand",
            ],
            educationRequirements: {
              "@type": "EducationalOccupationalCredential",
              credentialCategory: "Surfing experience and teaching ability",
            },
            experienceRequirements: {
              "@type": "OccupationalExperienceRequirements",
              monthsOfExperience: 12,
            },
            industry: "Sports Instruction",
            occupationalCategory: "Sports Instructor",
            skills: [
              "Surfing",
              "Teaching",
              "Water Safety",
              "Customer Service",
              "Communication",
            ],
            jobBenefits: [
              "Flexible Schedule",
              "Independent Contractor",
              "Commission-free Platform",
              "Professional Development",
            ],
            applicationContact: {
              "@type": "ContactPoint",
              contactType: "Application Contact",
              url: "https://getsurfinglessons.com/apply-to-coach",
            },
            potentialAction: {
              "@type": "ApplyAction",
              target: {
                "@type": "EntryPoint",
                urlTemplate: "https://getsurfinglessons.com/apply-to-coach",
                actionPlatform: [
                  "http://schema.org/DesktopWebPlatform",
                  "http://schema.org/MobileWebPlatform",
                ],
              },
            },
            workHours: "Flexible hours",
            specialCommitments:
              "Must have own transportation and surfing equipment",
            jobStartDate: "Immediate",
            jobImmediateStart: true,
            incentiveCompensation: [
              "Keep 100% of earnings after first lesson",
              "Set your own rates",
              "No platform fees",
            ],
          })}
        </script>
      </Helmet>
      <div className="apply-coach-container">
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>Submitting... Please wait.</p>
          </div>
        )}

        {/* Hero Section with optimized background loading */}
        <section
          className={`apply-coach-hero ${
            heroImageLoaded ? "image-loaded" : ""
          }`}
          style={{
            backgroundColor: "#1976d2", // Placeholder color while image loads
            backgroundImage: heroImageLoaded
              ? `url(${process.env.PUBLIC_URL}/images/new-6.jpg)`
              : "none",
          }}
        >
          <div className="hero-content-2">
            <div className="hero-text">
              <h1>Share Your Passion</h1>
              <h2>Become a Surf Coach</h2>
              <p>
                Join our community of expert instructors and inspire the next
                generation of surfers
              </p>
              <button onClick={scrollToForm} className="cta-button-apply">
                Apply Now!
              </button>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="benefits-section">
          <h2>Why Choose Us?</h2>
          <div className="benefits-grid">
            <div className="benefit-card">
              <i className="fas fa-money-bill-wave"></i>
              <h3>Keep 100% Earnings</h3>
              <p>
                Earn what you deserve with zero platform fees! Creating and
                maintaining your coaching profile is completely free. You keep
                100% of your earnings—except for the first lesson, which has a
                50% fee. After that, you keep your full earnings on every
                lesson.
              </p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-clock"></i>
              <h3>Flexible Schedule</h3>
              <p>
                Work when and where you want. Set your own hours and choose
                which lessons to accept based on your availability.
              </p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-users"></i>
              <h3>Growing Community</h3>
              <p>
                Join our thriving community of surf enthusiasts. Connect with
                students who are eager to learn and share your passion for
                surfing.
              </p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-star"></i>
              <h3>Build Your Brand</h3>
              <p>
                Establish your reputation as an expert. Create a professional
                profile, showcase your experience, and grow your client base.
              </p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-globe"></i>
              <h3>Nationwide Reach</h3>
              <p>
                Connect with students from across the country. Teach at your
                favorite spots and expand your teaching locations.
              </p>
            </div>
            <div className="benefit-card">
              <i className="fas fa-shield-alt"></i>
              <h3>Full Control</h3>
              <p>
                Set your own rates for private and group lessons. You're in
                complete control of your coaching business.
              </p>
            </div>
          </div>
          <div className="benefits-footer">
            <p>
              Join our platform today and become part of the fastest-growing
              surf coaching community in the United States!
            </p>
            <p className="highlight-text">
              No subscription fees • No commission fees • No hidden charges
            </p>
          </div>
        </section>

        {/* Application Form */}
        <section className="application-section" ref={formRef}>
          <div className="form-container">
            <h2>Apply to Become a Coach</h2>
            <form onSubmit={handleSubmit} className="application-form">
              <div className="form-grid">
                <div className="form-group">
                  <label>Personal Information</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Location & Experience</label>
                  <input
                    type="text"
                    placeholder="State"
                    value={state}
                    onChange={(e) => setStateName(e.target.value)}
                    required
                  />
                  <textarea
                    placeholder="Cities you teach in (comma-separated)"
                    value={cities}
                    onChange={(e) => setCities(e.target.value)}
                    required
                  />
                  <input
                    type="number"
                    placeholder="Years of Experience"
                    value={experience}
                    onChange={(e) => setExperience(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Pricing</label>
                  <div className="price-inputs">
                    <input
                      type="number"
                      placeholder="Private Lesson Rate ($)"
                      value={privateLessonRate}
                      onChange={(e) => setPrivateLessonRate(e.target.value)}
                      required
                    />
                    <input
                      type="number"
                      placeholder="Group Lesson Rate ($)"
                      value={groupLessonRate}
                      onChange={(e) => setGroupLessonRate(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="form-group full-width">
                  <label>About You</label>
                  <textarea
                    placeholder="Tell us about your experience, teaching philosophy, and what makes you unique..."
                    value={profileAbout}
                    onChange={(e) => setProfileAbout(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label>Profile Picture</label>
                  <div className="file-upload">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      required
                    />
                    <i className="fas fa-cloud-upload-alt"></i>
                    <span>Choose a file</span>
                  </div>
                </div>
              </div>

              {warning && <div className="warning-message">{warning}</div>}

              <div className="terms-section">
                <label className="checkbox-label">
                  <input type="checkbox" required />
                  <span>
                    I agree to the <Link to="/terms">Terms of Service</Link>,{" "}
                    <Link to="/privacy">Privacy Policy</Link>, and{" "}
                    <Link to="/safety">Safety Guidelines</Link>
                  </span>
                </label>
              </div>

              <button type="submit" className="submit-button">
                Submit Application
              </button>
            </form>
          </div>
        </section>

        {/* Success Modal with lazy loaded icon */}
        {isModalOpen && (
          <div className="apply-success-overlay">
            <div className="apply-success-modal">
              <div className="apply-success-content">
                <div className="apply-success-icon">
                  <Suspense fallback={<div className="icon-placeholder" />}>
                    <FaCheckCircle />
                  </Suspense>
                </div>
                <h2 className="apply-success-title">Application Submitted!</h2>
                <p className="apply-success-message">{successMessage}</p>
                <button onClick={closeModal} className="apply-success-btn">
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
